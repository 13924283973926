import React from 'react';
import styled, { css } from 'styled-components';

import { pxToRem } from '../../helpers/pxToRem';

interface InputProps {
  type?: string;
  label?: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  dark?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pattern?: string;
  title?: string;
  step?: string;
  min?: string;
  multiple?: boolean;
  accept?: string;
}

const Input: React.FC<InputProps> = ({
  type = 'text',
  label,
  name,
  placeholder,
  disabled,
  required,
  dark,
  value,
  onChange,
  pattern,
  title,
  step,
  min,
  multiple,
  accept,
}) => {
  return (
    <>
      <StyledLabel disabled={disabled}>
        {label && <span>{label}</span>}

        {type === 'file' && <AddImage>ADD IMAGE</AddImage>}

        <StyledInput
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          dark={dark}
          value={value}
          onChange={onChange}
          pattern={pattern}
          title={title}
          step={step}
          min={min}
          multiple={multiple}
          accept={accept}
        />
      </StyledLabel>

      {/* #26: error messages  */}
    </>
  );
};

const StyledInput = styled.input<Pick<InputProps, 'dark'>>`
  position: relative;
  border: 1px solid transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  padding: ${pxToRem('20px')} ${pxToRem('5px')};
  width: 100%;
  flex: 2;
  background-color: transparent;
  color: ${({ dark }) => dark && '#ffffff'};

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey};
    opacity: 1;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.lightGrey};
    cursor: not-allowed;
  }

  ${({ type }) =>
    type === 'file' &&
    css`
      display: none;
    `}
`;

const StyledLabel = styled.label<Pick<InputProps, 'disabled'>>`
  display: flex;

  span {
    flex: 1;
    align-self: center;
    font-size: ${pxToRem('20px')};
    color: ${({ theme, disabled }) => disabled && theme.colors.grey};
    margin: 0 ${({ theme }) => theme.spacing};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const AddImage = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultTint};
  color: white;
  border-radius: 3px;
  text-align: center;
  margin-top: 5px;
  padding: 10px;
  cursor: pointer;
  flex: 2;
`;

export default Input;
