import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { formatISO, parseISO, format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import AppContext from '../../context/context';
import TripCardForm from './TripCardForm';
import { Loader } from '../loader';

import {
  Card,
  Comments,
  CustomerDetails,
  TripInformation,
  IconBlock,
  PickDropBlock,
} from '../card';
import { Container } from '../container';
import { Button } from '../button';
import { Toaster } from '../toaster';

import {
  TripStatus,
  SFTripStatus,
  SubmitTripFormProps,
  MenuTypes,
  Trip,
} from '../../types/types';

import {
  refetchDriverDetails,
  changeTripStatus,
  completeTrip,
} from '../../helpers/trips';

interface Props {
  trip: Trip;
  menuType: MenuTypes;
}

const TripCard: React.FC<Props> = ({ trip, menuType }) => {
  const {
    id,
    tripName,
    commentsFromHenk,
    customerName,
    pickupTime,
    pickupPlace,
    dropoffTime,
    dropoffPlace,
    status,
  } = trip;

  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [message, setMessage] = useState<{
    type: 'success' | 'error';
    text: string;
  } | null>(null);
  const history = useHistory();

  const setTripsStatus = async (newStatus: SFTripStatus) => {
    setLoading(true);

    try {
      await changeTripStatus(trip, newStatus, state?.oAuthToken);
      await refetchDriverDetails(state, dispatch);
      setMessage(null);

      if (newStatus === SFTripStatus.ONGOING) {
        history.push('/current-trip');
      }
    } catch (error) {
      setMessage({
        type: 'error',
        text: `Submit failed ${error}`,
      });
    }

    setLoading(false);
  };

  const endTrip = () => {
    setShowForm(true);
  };

  const submitTripForm = async ({
    fare,
    vehicleNo,
    waiting,
    parking,
    comments,
    images,
  }: SubmitTripFormProps) => {
    setLoading(true);

    try {
      const theDropoffTime = dropoffTime
        ? formatISO(parseISO(dropoffTime))
        : formatISO(new Date());

      await completeTrip(
        id || '',
        parking,
        waiting,
        images.map(({ name, imageSrc }) => ({
          type: name,
          base64: imageSrc.split(',')[1], // strip file type from string
        })),
        theDropoffTime,
        fare,
        comments,
        vehicleNo,
        state?.oAuthToken
      );

      setShowForm(false);
      setMessage({
        type: 'success',
        text: 'Successfull',
      });

      dispatch({
        type: 'CHANGE_TRIP_STATUS',
        payload: {
          id,
          status: TripStatus.COMPLETED,
        },
      });
    } catch (error) {
      setMessage({
        type: 'error',
        text: `Submit failed ${error}`,
      });
    }

    setLoading(false);
  };

  return (
    <Card tripId={tripName}>
      {loading && <Loader />}

      {status === TripStatus.ASSIGNED && (
        <ButtonContainer verticalPadding horizontalPadding>
          <Button onClick={() => setTripsStatus(SFTripStatus.ACCEPTED)}>
            ACCEPT
          </Button>
          <Button
            variant="primary"
            onClick={() => setTripsStatus(SFTripStatus.REJECTED)}
          >
            REJECT
          </Button>
        </ButtonContainer>
      )}

      {customerName && <CustomerDetails name={customerName} />}

      <Comments>{commentsFromHenk}</Comments>

      <Container verticalPadding useMargin halfSpacing>
        <TripInformation
          leftBlock={
            <IconBlock icon="clock">
              {status === TripStatus.COMPLETED && 'pick - '}
              {format(parseISO(pickupTime as string), 'HH:mm')}
            </IconBlock>
          }
          rightBlock={
            <IconBlock icon="calendar">
              {format(parseISO(pickupTime as string), 'dd-MM-yyyy')}
            </IconBlock>
          }
        />
      </Container>

      {status === TripStatus.COMPLETED && (
        <Container verticalPadding useMargin halfSpacing>
          <TripInformation
            leftBlock={
              <IconBlock icon="clock">
                drop -{' '}
                {dropoffTime &&
                  format(parseISO(dropoffTime as string), 'HH:mm')}
              </IconBlock>
            }
            rightBlock={
              <IconBlock icon="calendar">
                {dropoffTime &&
                  format(parseISO(dropoffTime as string), 'dd-MM-yyyy')}
              </IconBlock>
            }
          />
        </Container>
      )}

      <Container verticalPadding useMargin>
        <TripInformation
          leftBlock={<PickDropBlock type="pickup">{pickupPlace}</PickDropBlock>}
          rightBlock={
            <PickDropBlock type="dropoff">{dropoffPlace}</PickDropBlock>
          }
        />
      </Container>

      <Container center>
        {status === TripStatus.ACCEPTED && menuType === MenuTypes.TODAY && (
          <Button onClick={() => setTripsStatus(SFTripStatus.ONGOING)}>
            START TRIP
          </Button>
        )}

        {status === TripStatus.ONGOING && !showForm && (
          <Button onClick={endTrip}>END TRIP</Button>
        )}
      </Container>

      {showForm && <TripCardForm onSubmit={submitTripForm} />}

      {message?.text && (
        <Toaster
          message={message.text}
          isActive={!!message.text}
          messageType={message.type}
          onClick={() => setMessage(null)}
        />
      )}
    </Card>
  );
};

const ButtonContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 0;
`;

export default TripCard;
