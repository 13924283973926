import React, { useContext, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import PullToRefresh from 'pulltorefreshjs';
import { differenceInSeconds } from 'date-fns';

import AppContext from '../../context/context';
import { getTrips, refetchDriverDetails } from '../../helpers/trips';
import { Container } from '../../components/container';
import { TripCard } from '../../components/TripCard';

import { MenuTypes } from '../../types/types';

import LoaderImg from '../../components/loader/images/loader.svg';

interface Props {
  menuType: MenuTypes;
}

// had to put this let here because it was not updated correctly
let updatedAt = new Date();

const TripList: React.FC<Props> = ({ menuType }) => {
  const { state, dispatch } = useContext(AppContext);

  /* refetch data when back in foreground */
  const visiblityCheck = () => {
    if (!document.hidden && differenceInSeconds(new Date(), updatedAt) > 10) {
      refetchDriverDetails(state, dispatch);
    }
  };

  useEffect(() => {
    updatedAt = state?.driverDetails?.updatedAt as Date;
  }, [state]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    PullToRefresh.init({
      mainElement: '.trip-list',
      onRefresh: async () => {
        await refetchDriverDetails(state, dispatch);
      },
      distReload: 62,
      iconRefreshing: ReactDOMServer.renderToString(
        <img src={LoaderImg} style={{ width: '20px' }} alt="" />
      ),
    });

    // check if app becomes 'visible' (in foreground) and refetch data
    document.addEventListener('visibilitychange', visiblityCheck, false);

    return () => {
      PullToRefresh.destroyAll();
      document.removeEventListener('visibilitychange', visiblityCheck);
    };
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const trips = getTrips(menuType, state?.driverDetails);
  return (
    <Container type="article" className="trip-list">
      {trips &&
        trips.map((trip) => (
          <TripCard key={trip.id} menuType={menuType} trip={trip} />
        ))}

      {!trips?.length && (
        <NothingMessage>
          <p>No {menuType.toLowerCase()} trips to show</p>
        </NothingMessage>
      )}
    </Container>
  );
};

export default TripList;

const NothingMessage = styled.div`
  color: white;
  display: flex;
  height: calc(100vh - 100px);
  width: 100%;
  align-items: center;
  justify-items: center;

  p {
    text-align: center;
    width: 100%;
  }
`;
