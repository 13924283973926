import React, { FormEvent, useState } from 'react';
import styled from 'styled-components';

import { Container } from '../container';
import { Input } from '../input';
import { Button } from '../button';
import { SubmitTripFormProps, Image } from '../../types/types';

import { getFilePreview, acceptedTypes } from '../../helpers/imageUpload';

interface Props {
  onSubmit(options: SubmitTripFormProps): void;
}

const TripCardForm: React.FC<Props> = ({ onSubmit }) => {
  const [fare, setFare] = useState('');
  const [vehicleNo, setVehicleNo] = useState('');
  const [waiting, setWaiting] = useState('0');
  const [parking, setParking] = useState('0');
  const [comments, setComments] = useState('');
  const [images, setImages] = useState<Image[]>([]);

  const handleImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      [...files].forEach(async (file) => {
        if (acceptedTypes.includes(file.type.toLowerCase())) {
          const imageResult = await getFilePreview(file);

          setImages((prevImages) => [
            ...prevImages,
            { name: file.name.toString(), imageSrc: imageResult },
          ]);
        }
      });
    }
  };

  const removeImage = (imageToRemove: string) => {
    setImages((prevImages) => {
      const imgIndex = prevImages.findIndex(
        (image) => image.imageSrc === imageToRemove
      );
      return [
        ...prevImages.slice(0, imgIndex),
        ...prevImages.slice(imgIndex + 1),
      ];
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ fare, vehicleNo, waiting, parking, comments, images });
  };

  return (
    <form onSubmit={handleSubmit} lang="nl">
      <Input
        type="number"
        name="fare"
        label="Fare in &euro; (if Cash/CC) *"
        value={fare}
        onChange={(e) => setFare(e.target.value)}
        required
        title="Fare should be in euro's like: 10,15"
        step="0.01"
        min="0"
      />
      <Input
        name="vehicleNo"
        label="Vehicle No."
        placeholder="A17"
        value={vehicleNo}
        onChange={(e) => setVehicleNo(e.target.value)}
      />
      <Input
        type="number"
        name="waiting"
        label="Waiting time (minutes)"
        value={waiting}
        onChange={(e) => setWaiting(e.target.value)}
        title="Waiting time you be in minutes like: 15"
        step="1"
        min="0"
      />
      <Input
        type="number"
        name="parking"
        label="Parking charges (&euro;)"
        value={parking}
        onChange={(e) => setParking(e.target.value)}
        title="Parking charges should be in euro's like: 10,15"
        step="0.01"
        min="0"
      />
      <Input
        name="comments"
        label="Comments"
        value={comments}
        onChange={(e) => setComments(e.target.value)}
      />

      <Input
        type="file"
        label="Images"
        name="images"
        onChange={handleImage}
        multiple
        accept={acceptedTypes.join(',')}
      >
        Add Image
      </Input>

      {images.length > 0 &&
        images.map((image) => (
          <ImageWrapper key={image.imageSrc}>
            <img src={image.imageSrc} width="80" alt="" />
            <RemoveButton onClick={() => removeImage(image.imageSrc)}>
              x
            </RemoveButton>
          </ImageWrapper>
        ))}

      <Container center verticalPadding>
        <Button type="submit">DONE</Button>
      </Container>
    </form>
  );
};

const ImageWrapper = styled.div`
  display: flex;
  padding: 0 ${({ theme }) => theme.spacing};
  align-items: center;
  margin: 10px 0;
`;

const RemoveButton = styled.button`
  color: red;
  border: 1px solid red;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: inline-block;
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing};
  transition: color 0.2s, background-color 0.2s;
  line-height: 0;

  &:hover {
    background-color: red;
    color: white;
  }
`;

export default TripCardForm;
