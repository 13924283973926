import React from 'react';
import styled from 'styled-components';

import { pxToRem } from '../../helpers/pxToRem';

type ToasterMessageType = 'success' | 'error' | 'warning' | 'default';

interface ToasterProps {
  message: string;
  isActive: boolean;
  onClick: () => void;
  messageType?: ToasterMessageType;
}

const Toaster = ({
  message,
  isActive,
  onClick,
  messageType = 'default',
}: ToasterProps) => (
  <ToasterContainer
    isVisible={isActive}
    messageType={messageType}
    data-testid="toaster"
  >
    <ToasterText>{message}</ToasterText>
    <CloseButton onClick={onClick}>close</CloseButton>
  </ToasterContainer>
);

const COLORMAP = {
  error: 'orangered',
  warning: '#FDB200',
  default: '#ffffff30',
  success: '#26a526',
};

const ToasterContainer = styled.section<{
  isVisible: boolean;
  messageType: ToasterMessageType;
}>`
  position: fixed;
  width: 100%;
  background-color: darkslategray;
  padding: ${pxToRem('20px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition-delay: 0.5s;
  transition: bottom 0.45s ease-in-out;
  left: 0;
  right: 0;
  top: ${({ isVisible }) => (isVisible ? 0 : '-100%')};
  border-top: 5px solid ${({ messageType }) => COLORMAP[messageType]};
`;

const ToasterText = styled.p`
  color: white;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 25px;
  width: 25px;
  position: relative;
  font-size: 0;

  &::after,
  &::before {
    content: '';
    height: 100%;
    width: 2px;
    background-color: white;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
`;

export default Toaster;
