interface FileExt {
  fileType: string;
  extensions: string[];
}

/**
 * Supported file type and extensions of image files.
 */
const imageFileTypeMappings: FileExt[] = [
  {
    fileType: 'image/jpeg',
    extensions: ['jpg', 'jpeg'],
  },
  {
    fileType: 'image/bmp',
    extensions: ['bmp'],
  },
  {
    fileType: 'image/png',
    extensions: ['png'],
  },
  {
    fileType: 'image/gif',
    extensions: ['gif'],
  },
  {
    fileType: 'image/heic',
    extensions: ['heic'],
  },
];

/**
 * A unique array of accepted file types
 */
export const acceptedTypes = [
  ...new Set(imageFileTypeMappings.map((item) => item.fileType)),
];

export const getFilePreview = (file: File) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (e.target?.result) {
        resolve(reader.result as string);
      }
    };

    reader.readAsDataURL(file);
  });
};
