import React, { useState, useEffect, useContext, useReducer } from 'react';
import { ThemeProvider } from 'styled-components';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { mainTheme, GlobalStyle } from './theme/theme';
import { Header } from './components/header';
import { Menu, MenuContainer, MenuTrigger } from './components/menu';
import { Container } from './components/container';

import { getTrips } from './helpers/trips';
import { MenuTypes } from './types/types';

import { Login, TripList } from './screens';

import AppContext from './context/context';
import reducer from './context/reducer';

export const initialMenuItems = [
  {
    pageType: MenuTypes.REQUEST,
    label: 'Trip Requests',
    link: '/trip-requests',
    numOfItems: 0,
  },
  {
    pageType: MenuTypes.TODAY,
    label: `Today's Trips`,
    link: '/todays-trips',
    numOfItems: 0,
  },
  {
    pageType: MenuTypes.UPCOMING,
    label: 'Upcoming Trips',
    link: '/upcoming-trips',
    numOfItems: 0,
  },
  {
    pageType: MenuTypes.CURRENT,
    label: 'Current Trip',
    link: '/current-trip',
  },
  {
    pageType: MenuTypes.PAST,
    label: 'Past Trips',
    link: '/past-trips',
  },
];

function App() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [menuItems, setMenuItems] = useState(initialMenuItems);
  const [pageTitle, setPageTitle] = useState('');
  const location = useLocation();

  const initialState = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { driverDetails } = state;

  /* prevent scrolling when menu is open */
  useEffect(() => {
    document
      .querySelector('body')!
      .setAttribute('style', toggleMenu ? 'overflow: hidden' : '');
  }, [toggleMenu]);

  useEffect(() => {
    const getLabel = menuItems.find(
      (menuItem) => menuItem.link === location.pathname
    );

    setPageTitle(getLabel?.label || '');
  }, [location, menuItems]);

  /* eslint-disable */
  useEffect(() => {
    setTripNumbers();
  }, [state]);
  /* eslint-enable */

  const setTripNumbers = () => {
    const menuItemTypes: MenuTypes[] = [
      MenuTypes.REQUEST,
      MenuTypes.TODAY,
      MenuTypes.UPCOMING,
      MenuTypes.CURRENT,
      MenuTypes.PAST,
    ];

    menuItemTypes.forEach((menuItemType) => {
      const trips = getTrips(menuItemType, driverDetails);
      if (trips) {
        const menuItemIndex = menuItems.findIndex(
          (menuItem) => menuItem.pageType === menuItemType
        );
        if (menuItemIndex >= 0) {
          const menuItem = menuItems[menuItemIndex];
          menuItem.numOfItems = trips.length;
          menuItems.splice(menuItemIndex, 1, menuItem);
          setMenuItems(menuItems);
        }
      }
    });
  };

  const handleTouchMove = () => {
    if (!toggleMenu) return;
    setToggleMenu(false);
  };

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={mainTheme}>
        <GlobalStyle />

        <MenuContainer isActive={toggleMenu}>
          <Menu
            menuItems={menuItems}
            isActive={toggleMenu}
            setToggleMenu={setToggleMenu}
          />

          <main onTouchMove={toggleMenu ? handleTouchMove : undefined}>
            {location.pathname !== '/login' && (
              <Header
                title={pageTitle}
                leftSide={
                  <MenuTrigger
                    toggleState={() => setToggleMenu((prevState) => !prevState)}
                    isActive={toggleMenu}
                  />
                }
              />
            )}

            <Container horizontalPadding className="main-container">
              <Switch>
                {menuItems.map((menuItem) => (
                  <Route key={menuItem.link} path={menuItem.link}>
                    <TripList menuType={menuItem.pageType} />
                  </Route>
                ))}

                <Route path="/login">
                  <Login />
                </Route>

                <Redirect exact from="/" to={menuItems[0].link} />
              </Switch>
            </Container>
          </main>
        </MenuContainer>

        {!Object.keys(driverDetails).length && <Redirect to="/login" />}
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
