import React, { useContext } from 'react';
import styled from 'styled-components';
import { format, differenceInHours, formatDistance } from 'date-fns';

import MenuItem from './MenuItem';
import Logo from '../logo/Logo';
import AppContext from '../../context/context';

import { menuTransition } from './menuTransition';

interface Props {
  isActive: boolean;
  setToggleMenu: (toggle: boolean) => void;
  menuItems: { label: string; link: string; numOfItems?: number }[];
}

const Menu: React.FC<Props> = ({ menuItems, isActive, setToggleMenu }) => {
  const { state } = useContext(AppContext);

  const getFormatterDateString = (date: Date) => {
    if (differenceInHours(new Date(), date) < 2) {
      return `${formatDistance(new Date(), date)} ago`;
    }

    return `at ${format(date, 'dd-MM-yyyy hh:mm')}`;
  };

  return (
    <MenuNav isActive={isActive}>
      <Logo />

      <MenuList>
        {menuItems.map((item) => (
          <MenuItem
            label={item.label}
            link={item.link}
            numOfItems={item.numOfItems}
            setToggleMenu={setToggleMenu}
            key={item.label}
          />
        ))}
      </MenuList>

      {state?.driverDetails?.updatedAt && (
        <DateLine>
          Trips data updated{' '}
          {getFormatterDateString(state?.driverDetails?.updatedAt)}
        </DateLine>
      )}
    </MenuNav>
  );
};

const MenuNav = styled.nav<Pick<Props, 'isActive'>>`
  position: fixed;
  left: 0;
  top: 0;
  padding: 1rem 1rem 0;
  height: 100%;
  width: 75%;
  transition: transform ${menuTransition};
  transform: ${({ isActive }) =>
    isActive ? 'translateX(0)' : 'translateX(-100%)'};
  border-right: 1px solid rgba(255, 255, 255, 0.25);
  overflow-y: auto;
`;

const MenuList = styled.ul`
  list-style: none;
  padding-bottom: 3rem;
`;

const DateLine = styled.p`
  color: white;
  padding: 1rem 1rem 1rem 2rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  font-size: 0.75rem;
`;

export default Menu;
