import React from 'react';
import styled from 'styled-components';

import LoaderImg from './images/loader.svg';

export default () => (
  <Loader>
    <img src={LoaderImg} alt="Loading..." />
  </Loader>
);

const Loader = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;
