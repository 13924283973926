import React from 'react';
import styled from 'styled-components';
import { Container } from '../container';
import { pxToRem } from '../../helpers/pxToRem';

interface CardContainer {
  tripId?: string;
}

const CardContainer: React.FC<CardContainer> = ({ tripId, children }) => {
  return (
    <Card type="article" verticalPadding useMargin>
      {tripId && (
        <Header>
          <CardTitle>{tripId}</CardTitle>
        </Header>
      )}

      {children}
    </Card>
  );
};

const Card = styled(Container)`
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${pxToRem('10px')} 0;

  :last-child {
    margin-bottom: ${({ theme }) => `${pxToRem(theme.spacingNumber * 2)}rem`};
  }
`;

const Header = styled.header`
  padding: 0 ${({ theme }) => theme.spacing} ${({ theme }) => theme.spacing};
`;

const CardTitle = styled.h2`
  font-weight: 400;
  font-size: ${pxToRem('14px')};
  margin: 8px 0 0;
`;

export default CardContainer;
