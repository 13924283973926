import { ReactText } from 'react';
import { DefaultTheme, createGlobalStyle } from 'styled-components';
import { pxToRem } from '../helpers/pxToRem';

declare module 'styled-components' {
  export interface DefaultTheme {
    borderRadius: string;
    spacing: ReactText;
    spacingNumber: number;

    colors: {
      default: string;
      defaultTint: string;
      primary: string;
      primaryTint: string;
      grey: string;
      lightGrey: string;
    };
  }
}

const GlobalStyle = createGlobalStyle`
 * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 15px;
 }

  html, body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: black;
  }
`;

const defaultSpacing = 20;

const mainTheme: DefaultTheme = {
  spacing: pxToRem(`${defaultSpacing}px`),
  spacingNumber: defaultSpacing,
  borderRadius: '3px',

  colors: {
    default: '#FDB200',
    defaultTint: '#b98201',
    primary: '#2D3648',
    primaryTint: '#212835',
    grey: '#979797',
    lightGrey: '#e8e5e5',
  },
};

export { mainTheme, GlobalStyle };
