import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { menuTransition } from './menuTransition';

interface Props {
  label: string;
  link: string;
  numOfItems?: number;
  setToggleMenu: (toggle: boolean) => void;
}

const MenuItem: React.FC<Props> = ({
  label,
  link,
  numOfItems,
  setToggleMenu,
}) => (
  <MenuItemLi>
    <Link to={link} onClick={() => setToggleMenu(false)}>
      {label} {numOfItems ? `(${numOfItems})` : ''}
    </Link>
  </MenuItemLi>
);

const MenuItemLi = styled.li`
  list-style: none;

  a {
    color: white;
    font-size: 1.2rem;
    text-decoration: none;
    transition: color ${menuTransition}, border-bottom ${menuTransition};
    display: block;
    padding: 1rem 0;
    margin: 0 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.default};

    &:hover {
      color: ${({ theme }) => theme.colors.default};
      border-color: ${({ theme }) => theme.colors.defaultTint};
    }
  }
`;

export default MenuItem;
