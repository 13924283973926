import React, { FormEvent, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AppContext from '../../context/context';
import { Container } from '../../components/container';
import { Logo } from '../../components/logo';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Loader } from '../../components/loader';
import { Toaster } from '../../components/toaster';
import { getDriverDetails } from '../../helpers/trips';

import { BeforeInstallPromptEvent } from '../../types/types';

declare global {
  interface Window {
    askForNotificationPermission(email: string): void;
    deferredAppInstallPrompt: BeforeInstallPromptEvent;
  }
}

const setPWAPrompts = (email: string) => {
  window.askForNotificationPermission(email);

  // check if available
  if (window.deferredAppInstallPrompt) {
    // Show the install prompt
    window.deferredAppInstallPrompt.prompt();
  }
};

const Login = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { dispatch } = useContext(AppContext);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const formElements = event.currentTarget.elements;
    const email = (formElements.namedItem('email') as HTMLInputElement).value;
    const password = (formElements.namedItem('password') as HTMLInputElement)
      .value;

    getTheDriverDetails(email, password);
  };

  const getTheDriverDetails = async (email: string, password: string) => {
    setLoading(true);

    try {
      const { driverDetails, oAuthToken } = await getDriverDetails(
        email,
        password,
        true
      );
      driverDetails.updatedAt = new Date();

      dispatch({ type: 'SET_DRIVERDETAILS', payload: driverDetails });
      dispatch({ type: 'SET_OAUTH_TOKEN', payload: oAuthToken });

      setPWAPrompts(email);

      setLoading(false);
      history.push('/');
    } catch (errorResponse) {
      setError(errorResponse.message);
      setLoading(false);
    }
  };

  return (
    <Container verticalPadding>
      <LoginContainer>
        <div>
          <Logo />
        </div>

        {loading && <Loader />}

        <Form onSubmit={handleSubmit}>
          <Input type="email" name="email" required placeholder="Email" dark />
          <Input
            type="password"
            name="password"
            required
            placeholder="Password"
            dark
          />

          <ButtonWrapper>
            <Button type="submit">LOGIN</Button>
          </ButtonWrapper>
        </Form>
      </LoginContainer>
      <Toaster
        message={error}
        isActive={!!error}
        messageType="error"
        onClick={() => setError('')}
      />
    </Container>
  );
};

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 1rem;
`;
const LoginContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 80vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;
export default Login;
