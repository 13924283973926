import { SFTrip, TripStatus } from '../types/types';

export const normalizeTrip = ({
  Id,
  Trip_Id__c,
  Pickup_del__c,
  Drop_Off_del__c,
  Pick_Up_Time__c,
  Drop_Off_Time__c,
  Customer__r,
  Status__c,
  Base_Fare__c,
  Payment_Method__c,
  Comments_from_Henk__c,
}: SFTrip) => ({
  id: Id,
  commentsFromHenk: Comments_from_Henk__c,
  baseFare: Base_Fare__c,
  paymentType: Payment_Method__c,
  tripName: Trip_Id__c,
  pickupPlace: Pickup_del__c,
  pickupTime: Pick_Up_Time__c, // eslint-disable-line max-len, camelcase
  dropoffPlace: Drop_Off_del__c,
  dropoffTime: Drop_Off_Time__c, // eslint-disable-line max-len, camelcase
  customerName: (Customer__r || {}).Name, // eslint-disable-line max-len, camelcase
  status: normalizeStatus(Status__c),
});

const normalizeStatus = (status: string) => {
  switch (status.toLowerCase()) {
    case 'driver rejected':
      return TripStatus.REJECTED;
    case 'driver assigned':
      return TripStatus.ASSIGNED;
    case 'driver accepted':
      return TripStatus.ACCEPTED;
    default:
      // Completed, Requested, Ongoing
      return status.toLowerCase();
  }
};
