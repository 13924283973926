import React from 'react';
import styled, { css } from 'styled-components';
import { pxToRem } from '../../helpers/pxToRem';

type ContainerType = 'section' | 'article' | 'div';

interface ContainerProps {
  type?: ContainerType;
  verticalPadding?: boolean;
  horizontalPadding?: boolean;
  useMargin?: boolean;
  halfSpacing?: boolean;
  bgcolor?: string;
  center?: boolean;
  className?: string;
}

const Container: React.FC<ContainerProps> = ({
  type = 'section',
  verticalPadding,
  horizontalPadding,
  bgcolor,
  children,
  useMargin,
  halfSpacing,
  center,
  ...props
}) => (
  <ContainerTag
    as={type}
    verticalPadding={verticalPadding}
    horizontalPadding={horizontalPadding}
    useMargin={useMargin}
    halfSpacing={halfSpacing}
    bgcolor={bgcolor}
    center={center}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </ContainerTag>
);

const ContainerTag = styled.section<
  Pick<
    ContainerProps,
    | 'verticalPadding'
    | 'horizontalPadding'
    | 'bgcolor'
    | 'useMargin'
    | 'halfSpacing'
    | 'center'
  >
>`
  position: relative;
  width: 100%;
  background-color: ${({ bgcolor }) => bgcolor && bgcolor};

  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `};

  ${({ verticalPadding, horizontalPadding, useMargin, halfSpacing, theme }) => {
    if (halfSpacing) {
      return (
        (verticalPadding || horizontalPadding) &&
        css`
      ${useMargin ? 'margin' : 'padding'}: ${
          verticalPadding ? `${pxToRem(theme.spacingNumber / 2)}rem` : 0
        }
            ${horizontalPadding ? `${pxToRem(theme.spacingNumber / 2)}rem` : 0};
        `
      );
    }

    return (
      (verticalPadding || horizontalPadding) &&
      css`
        ${useMargin ? 'margin' : 'padding'}: ${
        verticalPadding ? theme.spacing : 0
      }
            ${horizontalPadding ? theme.spacing : 0};
        `
    );
  }};
`;

export default Container;
