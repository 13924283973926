import styled from 'styled-components';

type ButtonType = 'default' | 'primary';

interface ButtonProps {
  variant?: ButtonType;
  disabled?: boolean;
  onClick?: () => void;
}

const Button = styled.button<ButtonProps>`
  font-size: 0.85rem;
  padding: 0.8rem 1rem;
  color: white;
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-transform: uppercase;

  background-color: ${({ theme, variant }) =>
    variant === 'primary' ? 'black' : theme.colors.default};

  &:hover {
    background-color: ${({ theme, variant }) =>
      variant === 'primary'
        ? theme.colors.primaryTint
        : theme.colors.defaultTint};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey};
    cursor: not-allowed;
  }
`;

export default Button;
