import { AppStateProps } from '../types/types';

const reducer = (
  state: AppStateProps,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case 'SET_DRIVERDETAILS':
      return {
        ...state,
        driverDetails: action.payload,
      };

    case 'CHANGE_TRIP_STATUS': {
      const driverDetails = state?.driverDetails;
      if (driverDetails?.trips) {
        const trips = driverDetails.trips.map((trip) => {
          if (trip.id === action.payload.id) {
            trip.status = action.payload.status;
          }
          return trip;
        });

        const driverDetailsCopy = { ...driverDetails };
        driverDetailsCopy.trips = trips;
        driverDetailsCopy.id = 'blabla';

        return {
          ...state,
          driverDetails,
        };
      }

      return state;
    }

    case 'SET_OAUTH_TOKEN': {
      return {
        ...state,
        oAuthToken: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
