import { Token, State } from '../types/types';

export const fetchToken = async (refreshToken = false, state?: State) => {
  if (!refreshToken && state && state.oAuthToken) {
    return state.oAuthToken;
  }

  // For token api call via node server on firebase (so the login details are not frontend code)
  // TODO: make env prop dynamic based on env (dev or prod)
  const result = await (
    await fetch(
      'https://us-central1-van-der-valk-taxi.cloudfunctions.net/getToken',
      {
        method: 'POST',
        body: JSON.stringify({
          env: 'prod',
          // env: 'dev',
        }),
      }
    )
  ).json();

  if (result.error) {
    throw new Error(result.error_description);
  }

  return result as Token;
};

export default fetchToken;
