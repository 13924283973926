import React from 'react';
import styled from 'styled-components';

interface Props {
  type: 'pickup' | 'dropoff';
}

const PickDropBlock: React.FC<Props> = ({ type, children }) => (
  <StyledPickDropBlock type={type}>
    <p className="title">{type === 'pickup' ? 'PICKUP' : 'DROP OFF'}</p>
    <p>{children}</p>
  </StyledPickDropBlock>
);

const StyledPickDropBlock = styled.article<Props>`
  .title {
    margin-bottom: 10px;
  }
`;

export default PickDropBlock;
