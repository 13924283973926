import React from 'react';
import styled from 'styled-components';

import { menuTransition } from './menuTransition';
import { pxToRem } from '../../helpers/pxToRem';

interface Props {
  isActive: boolean;
}

const MenuContainer: React.FC<Props> = ({ isActive, children }) => (
  <StyledMenuContainer isActive={isActive}>{children}</StyledMenuContainer>
);

const StyledMenuContainer = styled.section<Props>`
  overflow: ${({ isActive }) => isActive && 'hidden'};

  main {
    transition: transform ${menuTransition};
    transform: ${({ isActive }) =>
      isActive ? 'translateX(75%)' : 'translateX(0)'};
    opacity: 1;
    min-height: 100vh;

    > .main-container {
      transition: opacity ${menuTransition};
      opacity: ${({ isActive }) => (isActive ? 0.5 : 1)};
    }

    /* pull to refresh styles */
    .ptr--ptr {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      font-weight: normal;
      background-color: rgba(255, 255, 255, 0.2);
      /* to pull it full width */
      margin: 0 -${({ theme }) => theme.spacing};
      width: calc(
        100% + ${({ theme }) => `${pxToRem(theme.spacingNumber * 2)}rem`}
      );
    }

    .ptr--text,
    .ptr--icon {
      color: rgba(255, 255, 255, 0.9);
    }
  }
`;

export default MenuContainer;
