import formurlencoded from 'form-urlencoded';
import { Token } from '../types/types';

export enum CONTENT_TYPE {
  FORM_URL_ENCODED = 'application/x-www-form-urlencoded',
  JSON = 'application/json',
}

const authHeaders = (token: Token) => {
  const accessToken = token?.access_token;
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return {};
};

const getInstanceUrl = (token: Token) => token?.instance_url;

interface fetchProps {
  url: string;
  method: 'GET' | 'POST' | 'PUT';
  headers?: {};
  body?: {};
  oAuthToken?: Token;
  contentType?: CONTENT_TYPE;
}

const _fetch = async ({
  url,
  method,
  headers = {},
  body,
  oAuthToken,
  contentType,
}: fetchProps) => {
  return fetch(
    oAuthToken ? (`${getInstanceUrl(oAuthToken)}${url}` as string) : url,
    {
      method,
      headers: {
        ...(oAuthToken ? (authHeaders(oAuthToken) as {}) : {}),
        ...headers,
      },
      body:
        method === 'POST' || method === 'PUT'
          ? contentType === CONTENT_TYPE.FORM_URL_ENCODED
            ? formurlencoded(body)
            : JSON.stringify(body)
          : undefined,
    }
  );
};

interface getProps {
  url: string;
  oAuthToken?: Token;
}

export const get = ({ url, oAuthToken }: getProps) =>
  _fetch({
    url,
    oAuthToken,
    method: 'GET',
  });

interface postProps {
  url: string;
  body: {};
  headers?: {};
  oAuthToken?: Token;
  contentType?: CONTENT_TYPE;
}

export const post = ({
  url,
  body,
  headers = {},
  oAuthToken,
  contentType = CONTENT_TYPE.FORM_URL_ENCODED,
}: postProps) =>
  _fetch({
    url,
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      ...headers,
    },
    body,
    oAuthToken,
    contentType,
  });

export const put = ({
  url,
  body,
  headers = {},
  oAuthToken,
  contentType = CONTENT_TYPE.FORM_URL_ENCODED,
}: postProps) =>
  _fetch({
    url,
    method: 'PUT',
    headers: {
      'Content-Type': contentType,
      ...headers,
    },
    body,
    oAuthToken,
    contentType,
  });
