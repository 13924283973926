import { isBefore, parseISO } from 'date-fns';
import { Trip } from '../types/types';

const sortTrips = (firstTrip: Trip, secondTrip: Trip) => {
  if (!firstTrip.pickupTime || !secondTrip.pickupTime) {
    return 0;
  }
  if (
    isBefore(parseISO(firstTrip.pickupTime), parseISO(secondTrip.pickupTime))
  ) {
    return -1;
  }
  if (
    isBefore(parseISO(secondTrip.pickupTime), parseISO(firstTrip.pickupTime))
  ) {
    return 1;
  }
  return 0;
};

export const sortAscending = (trip1: Trip, trip2: Trip) =>
  sortTrips(trip1, trip2);

export const sortDescending = (trip1: Trip, trip2: Trip) =>
  sortTrips(trip2, trip1);
