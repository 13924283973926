import React from 'react';
import styled from 'styled-components';

import LogoSrc from './images/logo.jpg';
import TaxiSrc from './images/taxi.png';

const Logo = styled.img`
  width: 118px;
  margin-bottom: 2rem;
`;

const TaxiLogo = styled.img`
  width: 46px;
  position: relative;
  top: -40px;
`;

export default () => (
  <>
    <Logo src={LogoSrc} alt="Van der Valk" />
    <TaxiLogo src={TaxiSrc} alt="Taxi" />
  </>
);
