import React from 'react';
import styled from 'styled-components';

import { Container } from '../container';
import { pxToRem } from '../../helpers/pxToRem';

interface CustomerDetailsProps {
  name: string;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({ name }) => (
  <CDContainer>
    <Title>{name}</Title>
    <Divider />
  </CDContainer>
);

const CDContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.spacing};
`;

const Title = styled.h2`
  font-weight: 400;
  font-size: ${pxToRem('18px')};
  margin: ${pxToRem('4px')} ${({ theme }) => theme.spacing};
`;

const Divider = styled.hr`
  background-color: ${({ theme }) => theme.colors.default};
  height: 1px;
`;

export default CustomerDetails;
