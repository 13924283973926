export interface Token {
  instance_url: string;
  access_token: string;
}

export interface SFTrip {
  Id: string;
  Trip_Id__c: string;
  Pickup_del__c: string;
  Drop_Off_del__c: string;
  Pick_Up_Time__c: string;
  Drop_Off_Time__c: string;
  Customer__r: { Name: string };
  Status__c: string;
  Base_Fare__c: string;
  Payment_Method__c: string;
  Comments_from_Henk__c: string;
}

export enum TripStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  ASSIGNED = 'assigned',
  COMPLETED = 'completed',
  ONGOING = 'ongoing',
}

export enum SFTripStatus {
  ACCEPTED = 'Driver Accepted',
  REJECTED = 'Driver Rejected',
  ONGOING = 'Ongoing',
}

export interface Trip {
  id?: string;
  tripName?: string;
  commentsFromHenk?: string;
  baseFare?: string;
  paymentType?: string;
  pickupPlace?: string;
  pickupTime?: string;
  dropoffPlace?: string;
  dropoffTime?: string;
  customerName?: string;
  status?: TripStatus;
}

export enum MenuTypes {
  TODAY = 'TODAY',
  REQUEST = 'REQUEST',
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
  CURRENT = 'CURRENT',
}

export type MenuTypesString = keyof typeof MenuTypes;

export interface DriverDetails {
  trips?: Trip[];
  id?: string;
  email?: string;
  password?: string;
  name?: string;
  updatedAt?: Date;
}

export interface State {
  driverDetails?: DriverDetails;
  oAuthToken?: Token;
}

export interface AppStateProps {
  driverDetails?: DriverDetails; // intial state on this level
  state?: State; // in context on this level
  dispatch: React.Dispatch<{ type: string; payload: any }>; // dispatch in context
}

export interface Image {
  name: string;
  imageSrc: string;
}

export interface SubmitTripFormProps {
  fare: string;
  vehicleNo: string;
  waiting: string;
  parking: string;
  comments: string;
  images: Image[];
}

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 * copy pasted from: https://stackoverflow.com/questions/51503754/typescript-type-beforeinstallpromptevent
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
export interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}
