import React from 'react';
import styled from 'styled-components';

import { pxToRem } from '../../helpers/pxToRem';
import { Container } from '../container';

interface TripInformationProps {
  leftBlock: string | React.ReactNode;
  rightBlock: string | React.ReactNode;
}

const TripInformation: React.FC<TripInformationProps> = ({
  leftBlock,
  rightBlock,
}) => (
  <TripContainer>
    <Block horizontalPadding verticalPadding>
      {leftBlock}
    </Block>
    <Block horizontalPadding verticalPadding>
      {rightBlock}
    </Block>
  </TripContainer>
);

const TripContainer = styled(Container)`
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: ${pxToRem('10px')};
  }
`;

const Block = styled(Container)`
  word-break: break-word;
  hyphens: auto;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: ${({ theme }) =>
    `${pxToRem(theme.spacingNumber / 2)}rem ${theme.spacing}`};

  &:not(:last-of-type) {
    margin-right: ${pxToRem('10px')};
  }

  @media screen and (max-width: 360px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export default TripInformation;
