import React from 'react';
import styled from 'styled-components';

import { Container } from '../container';
import { pxToRem } from '../../helpers/pxToRem';

const Comments: React.FC = ({ children }) => (
  <CommentContainer horizontalPadding>
    <Title>Comments:</Title>
    {children && <p>{children}</p>}
  </CommentContainer>
);

const CommentContainer = styled(Container)`
  font-size: ${pxToRem('14px')};
  margin-bottom: ${({ theme }) => theme.spacing};
`;

const Title = styled.h3`
  font-weight: 400;
  margin-bottom: ${pxToRem('5px')};
`;

export default Comments;
