import React from 'react';
import styled from 'styled-components';

import { pxToRem } from '../../helpers/pxToRem';

interface HeaderProps {
  title: string;
  leftSide?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ title, leftSide }) => {
  return (
    <StyledHeader>
      {leftSide && <HeaderLeft>{leftSide}</HeaderLeft>}
      <Title>{title}</Title>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  background-color: white;
  display: flex;
  padding: ${pxToRem('20px')};
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
`;

const HeaderLeft = styled.section`
  flex: 0 0 30px;
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: ${pxToRem('20px')};
  font-weight: 400;
`;

export default Header;
