import React from 'react';
import styled, { css } from 'styled-components';

interface MenuTriggerProps {
  isActive: boolean;
  toggleState: () => void;
}

const MenuTrigger: React.FC<MenuTriggerProps> = ({ isActive, toggleState }) => (
  <MenuButton onClick={toggleState}>
    <Hamburger isActive={isActive}>Menu</Hamburger>
  </MenuButton>
);

const MenuButton = styled.button`
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: linear opacity, filter 0.15s;
  outline: 0;
  background-color: transparent;
  position: relative;
  top: -3px;

  &:hover {
    opacity: 0.7;
  }
`;

const MenuLine = css`
  background-color: black;
  height: 3px;
  width: 100%;
`;

const Hamburger = styled.span<Pick<MenuTriggerProps, 'isActive'>>`
  ${MenuLine}
  position: relative;
  display: block;
  font-size: 0;
  transition: transform cubic-bezier(0.55, 0.055, 0.675, 0.19) 75ms;

  ${({ isActive }) =>
    isActive &&
    css`
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(45deg);
    `}

  &:before {
    content: '';
    ${MenuLine}
    position: absolute;
    top: -9px;
    left: 0;
    transition: top 75ms ease 0.12s, opacity 75ms ease;

    ${({ isActive }) =>
      isActive &&
      css`
        top: 0;
        opacity: 0;
        transform: rotate(0);
        transition: top 75ms ease, opacity 75ms ease 0.12s;
      `}
  }

  &:after {
    content: '';
    ${MenuLine}
    position: absolute;
    bottom: -9px;
    left: 0;
    transition: bottom 75ms ease 0.12s,
      transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);

    ${({ isActive }) =>
      isActive &&
      css`
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 75ms ease,
          transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
      `};
  }
`;

export default MenuTrigger;
