import React from 'react';
import styled from 'styled-components';

import clock from './images/clock.png';
import calendar from './images/calendar.png';

interface Props {
  icon: 'clock' | 'calendar';
}

const IconBlock: React.FC<Props> = ({ icon, children }) => (
  <IconBlockDiv>
    <img src={icon === 'clock' ? clock : calendar} alt="" />
    <span>{children}</span>
  </IconBlockDiv>
);

const IconBlockDiv = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;

  img {
    width: 20px;
    margin-right: 10px;
  }
`;

export default IconBlock;
