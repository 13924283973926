import { parseISO, isSameDay, isAfter } from 'date-fns';

import { normalizeTrip } from './storage';
import { sortAscending, sortDescending } from './sorting';
import {
  MenuTypes,
  TripStatus,
  Trip,
  DriverDetails,
  SFTripStatus,
  Token,
  State,
} from '../types/types';
import { get, post, put, CONTENT_TYPE } from './api';
import fetchToken from './token';

/** Get driver details (including trips) */

export const getDriverDetails = async (
  email: string,
  password: string,
  refetchToken: boolean,
  state?: State
) => {
  const oAuthToken = await fetchToken(refetchToken, state);

  const response = await get({
    url: `/services/apexrest/driverDetails?email=${email}&pwd=${password}`,
    oAuthToken,
  });

  if (!response.ok) {
    throw Error('Login failed');
  }

  const result = await response.json();

  const {
    Id,
    Email,
    FirstName,
    LastName,
    Trip_Requests__r = {}, // eslint-disable-line camelcase
  } = result.Data[0];

  const trips = Trip_Requests__r.records
    ? Trip_Requests__r.records.map(normalizeTrip)
    : [];

  const driverDetails = {
    id: Id,
    email: Email,
    password,
    name: `${FirstName} ${LastName}`,
    trips,
  } as DriverDetails;

  return { driverDetails, oAuthToken };
};

/** trip filtering */
interface filterProps {
  pickupTime?: string;
  status?: string;
}

const filters = {
  [MenuTypes.TODAY]: ({ pickupTime, status }: filterProps) =>
    status === TripStatus.ACCEPTED &&
    pickupTime &&
    isSameDay(parseISO(pickupTime), new Date()),
  [MenuTypes.REQUEST]: ({ status }: any) => status === TripStatus.ASSIGNED,
  [MenuTypes.UPCOMING]: ({ pickupTime, status }: filterProps) =>
    status === TripStatus.ACCEPTED &&
    pickupTime &&
    isAfter(parseISO(pickupTime), new Date()) &&
    !isSameDay(parseISO(pickupTime), new Date()),
  [MenuTypes.PAST]: ({ pickupTime, status }: filterProps) =>
    status === TripStatus.COMPLETED && pickupTime,
  [MenuTypes.CURRENT]: ({ status }: filterProps) =>
    status === TripStatus.ONGOING,
};

export const getTrips = (
  filterType: MenuTypes,
  driverDetails: DriverDetails = {}
) => {
  return (
    driverDetails.trips &&
    driverDetails.trips
      .filter(filters[filterType])
      .sort(filterType === MenuTypes.PAST ? sortDescending : sortAscending)
  );
};

/** changing trip status */

export const changeTripStatus = async (
  trip: Trip,
  status: SFTripStatus,
  oAuthToken: Token | undefined
) => {
  const result = await put({
    contentType: CONTENT_TYPE.JSON,
    url: `/services/apexrest/updateTrip/?id=${trip.id}`,
    body: {
      Id: trip.id,
      TripName: trip.tripName,
      Status: status,
    },
    oAuthToken,
  });

  return result;
};

/** complete trip */

const replaceComma = (str: string) => str.replace(',', '.');

export const completeTrip = async (
  tripId: string,
  parking: string,
  waiting: string,
  images: { type: string; base64: string }[],
  dropoffTime: string,
  fare: string,
  comments: string,
  vehicleNo: string,
  oAuthToken: Token | undefined
) => {
  const result = await post({
    contentType: CONTENT_TYPE.JSON,
    url: `/services/apexrest/completeTrip/?id=${tripId}`,
    body: {
      request: {
        status: 'Completed',
        driverAmount: replaceComma(fare),
        parking: replaceComma(parking),
        waiting: replaceComma(waiting),
        attachments: images,
        dropTime: dropoffTime,
        vehicleNumber: vehicleNo,
        driverComments: comments,
      },
    },
    oAuthToken,
  });

  if (result.status !== 200) {
    const errorMessage = await result.text();
    throw new Error(`Error from server (${result.status}): ${errorMessage}`);
  }
};

export const refetchDriverDetails = async (
  state: State | undefined,
  dispatch: React.Dispatch<{
    type: string;
    payload: any;
  }>
) => {
  const email = state?.driverDetails?.email;
  const password = state?.driverDetails?.password;

  if (email && password) {
    const { driverDetails, oAuthToken } = await getDriverDetails(
      email,
      password,
      false,
      state
    );
    driverDetails.updatedAt = new Date();

    dispatch({ type: 'SET_DRIVERDETAILS', payload: driverDetails });
    dispatch({ type: 'SET_OAUTH_TOKEN', payload: oAuthToken });
  }
};

// gets single trip data, not (yet) used
/* istanbul ignore next */
export const getTripDetails = (
  tripId: string,
  oAuthToken: Token | undefined
) => {
  return get({
    url: `/services/apexrest/tripDetails?id=${tripId}`,
    oAuthToken,
  })
    .then((response) => response.json())
    .then(({ Data }) => normalizeTrip(Data[0]))
    .then((trip) => {
      console.log('trip', trip);
    });
};
